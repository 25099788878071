     import { Link } from 'react-router-dom';

const MenuItems = (props) => {
    const { parentMenu } = props;

    return (
        <>
            <li className={parentMenu === 'home' ? 'rs-mega-menu current-menu-item' : 'rs-mega-menu'}>
                <Link to="/">Inicio</Link>
            </li>
            <li className={parentMenu === 'estatute' ? 'rs-mega-menu current-menu-item' : 'rs-mega-menu'}>
                <Link to="/estatutes" as="#">Estatutos</Link>
            </li>
            <li className={parentMenu === 'egresados' ? 'rs-mega-menu current-menu-item' : 'rs-mega-menu'}>
                <Link to="/egresados" as="#">Egresados</Link>
            </li>
            <li className={parentMenu === 'pages' ? 'rs-mega-menu current-menu-item' : 'rs-mega-menu'}>
                <Link to="#">Programa de Becas</Link>
            </li>
            <li className={parentMenu === 'contact' ? 'rs-mega-menu current-menu-item' : 'rs-mega-menu'}>
                <Link to="#">Contacto</Link>
            </li>
        </>
    );
}

export default MenuItems;
