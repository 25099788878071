import React from 'react';
import { Link } from 'react-router-dom';

import postImage from '../../assets/img/blog/inner/1.jpg';
import innerImage from '../../assets/img/blog/inner/2.jpg';

const PostContent = ({ }) => {
    return (
        <div className="blog-deatails">
            <div className="bs-img">
                <img
                    src={postImage}
                    alt="Post Image"
                />
            </div>
            <div className="blog-full">
                <ul className="single-post-meta">
                    <li>
                        <span className="p-date"> <i className="fa fa-calendar-check-o"></i> April 6, 2022 </span>
                    </li>
                    <li>
                        <span className="p-date"> <i className="fa fa-user-o"></i> Admin </span>
                    </li>
                    <li className="Post-cate">
                        <div className="tag-line">
                            <i className="fa fa-book"></i>
                            <Link to="/blog">Strategy</Link>
                        </div>
                    </li>
                    <li className="post-comment"> <i className="fa fa-comments-o"></i> 0</li>
                </ul>

                {/*Text generated*/}
                <div>

                    <p>Lunes, 07 Abril 2014 00:00<br/>Estatutos<br/>&nbsp;<br/>Estatutos de la Asociaci&oacute;n sin
                        fines de lucro &ldquo;ASOCIACI&Oacute;N DOMINICANA DE PROFESIONALES EGRESADOS DE RUSIA, EUROPA
                        DEL ESTE, ASIA CENTRAL Y CUBA (ADOPEREACU).&rdquo;<br/>CAPITULO I.
                        FORMA-NOMBRE-DURACION-DOMICILIO-OBJETIVOS<br/>Art 1.-&nbsp;Entre los suscriptores del presente
                        acto han quedado modificados los Estatutos de fecha 28 de febrero de 1986 y el Proyecto de
                        Modificaci&oacute;n estatutaria de fecha 19 de enero de 2002, de la asociaci&oacute;n sin fines
                        de lucro, regida por la ley 122-05, de fecha 8 de abril del a&ntilde;o 2005, sobre
                        Regulaci&oacute;n y Fomento de las Asociaciones Sin Fines de Lucro, relativa a asociaciones que
                        no tienen por objeto obtener beneficios pecuniarios.<br/>Art. 2.&nbsp;El nombre de la
                        asociaci&oacute;n que se rige por los presentes estatutos es la: &ldquo;ASOCIACION DOMINICANA DE
                        PROFESIONALES EGRESADOS DE RUSIA, EUROPA DEL ESTE, ASIA CENTRAL Y CUBA (ADOPEREACU)&rdquo; La
                        ADOPEREACU tiene capacidad jur&iacute;dica y en lo que sigue se
                        denominar&aacute; gen&eacute;ricamente la ASOCIACION.<br/>Art. 3.&nbsp;La duraci&oacute;n de la
                        Asociaci&oacute;n sin fines de lucros es por tiempo indefinido. Solo podr&aacute; disolverse en
                        la forma prevista por la ley 122-05 y los presentes estatutos.<br/>Art. 4.&nbsp;El domicilio
                        social de la Asociaci&oacute;n se establece accidentalmente y a los fines de ley en la calle
                        Rafael Hern&aacute;ndez 25, apt G5 , Ensanche Naco, Santo Domingo, Distrito Nacional, capital de
                        la Rep&uacute;blica Dominicana, pudiendo tener representaci&oacute;n en el resto del pa&iacute;s
                        y en el exterior.<br/>Art. 5.&nbsp;Los objetivos de la Asociaci&oacute;n son:<br/>Asociar a
                        todos los profesionales dominicanos y extranjeros residentes en el pa&iacute;s egresados de
                        Rusia, Europa del Este, Asia Central y Cuba.<br/>Realizar actividades tendentes a elevar la
                        formaci&oacute;n t&eacute;cnico-cient&iacute;fica de los miembros y promover la
                        recreaci&oacute;n y esparcimiento de los egresados.<br/>Constituir comisiones t&eacute;cnico-
                        cient&iacute;ficas permanentes por especialidades, para la evaluaci&oacute;n, elaboraci&oacute;n
                        y seguimiento de pol&iacute;ticas en las diferentes &aacute;reas del acontecer nacional e
                        internacional. Esas comisiones podr&aacute;n, a su vez, tener sub.-comisiones.<br/>Orientar a
                        los egresados y egresadas, y colaborar con su ubicaci&oacute;n en plazas de trabajo apropiadas
                        en especialidades y remuneraci&oacute;n.<br/>Defender solidaria y activamente a los egresados
                        ante problemas relacionados con el desempe&ntilde;o de su trabajo profesional.<br/>Velar por el
                        cumplimiento de la &eacute;tica profesional de los egresados en el ejercicio de sus
                        funciones.<br/>Asistencia y asesor&iacute;a t&eacute;cnica para los hijos y parientes de los
                        egresados.<br/>Estrechar lazos de amistad y colaboraci&oacute;n entre todos pueblos de la
                        comunidad internacional y el pueblo dominicano.<br/>Abrir un libro denominado &ldquo;Registro de
                        Socio&rdquo; el cual deber&aacute;n firmar todos los miembros de la asociaci&oacute;n.<br/>Colaborar
                        con la revalidaci&oacute;n de t&iacute;tulos a trav&eacute;s de las autoridades correspondientes
                        ya sean estas locales o extranjeras.<br/>Art. 6.&nbsp;La Asociaci&oacute;n sin fines de lucro
                        adquiri&oacute; personalidad jur&iacute;dica mediante decreto No. 766-86 de
                        fecha &hellip;&hellip;. del a&ntilde;o 1986, del Poder Ejecutivo.</p>
                    <p>Estatutos - Capitulo II<br/>&nbsp;<br/>CAPITULO II. Membrec&iacute;a de los Miembros de la
                        Asociaci&oacute;n.<br/>Art. 7.&nbsp;Podr&aacute;n ser miembros plenos de todo derecho todos los
                        profesionales dominicanos de origen y extranjeros naturalizados que sean egresados de Rusia,
                        Europa del Este, Asia Central y Cuba.<br/>Art. 8.&nbsp;La asociaci&oacute;n podr&aacute; tener
                        adem&aacute;s, los siguientes tipos de miembros: asociados y de honor.<br/>Art.
                        9.&nbsp;Podr&aacute;n ser miembros ASOCIADOS aquellos profesionales extranjeros residentes en el
                        pa&iacute;s que hayan estudiado en Rusia, Europa del Este, Asia Central y Cuba, y los
                        dominicanos que se encuentren estudiando en los citados pa&iacute;ses. En la asamblea
                        tendr&aacute;n derecho a voz.<br/>Art.10.&nbsp;Los miembros de HONOR podr&aacute;n ser aquellos
                        que por su prestigio o por haber contribuido de modo relevante a la dignificaci&oacute;n y
                        desarrollo de la ASOCIACI&Oacute;N, se hagan acreedores a tal distinci&oacute;n. Dicha
                        distinci&oacute;n ser&aacute; concedida por las 2/3 partes de Junta Directiva o 1/3 de la
                        asamblea.<br/>Art. 11.&nbsp;Para ser miembro pleno de la asociaci&oacute;n es necesario:<br/>Completar
                        una hoja de inscripci&oacute;n (empadronamiento) a la que el solicitante deber&aacute; colocarle
                        una foto tama&ntilde;o 2&rdquo;x2&rdquo;;<br/>Suministrar una copia fotost&aacute;tica del
                        diploma obtenido;<br/>Aportar una cuota de inscripci&oacute;n.<br/>Haberse graduado en una
                        instituci&oacute;n acad&eacute;mica de los antiguos y actuales pa&iacute;ses socialistas.<br/>Ser
                        dominicano de nacimiento o naturalizaci&oacute;n.<br/>Art. 12.&nbsp;SON DEBERES DE LOS MIEMBROS:<br/>Pagar
                        puntualmente la cuota mensual establecida por la Junta Directiva de turno.<br/>Asistir a las
                        asambleas y reuniones de los organismos a que pertenezcan.<br/>Acoger en su totalidad las normas
                        contenidas en los presentes estatutos, las disposiciones de los organismos de direcci&oacute;n y
                        los reglamentos que se aprueben en asambleas,<br/>Observar buena conducta dentro y fuera de la
                        asociaci&oacute;n, fraternizar y ser solidario con todos los miembros.<br/>Participar
                        activamente en los planes de trabajo y programas orientados a conseguir los objetivos de la
                        asociaci&oacute;n.<br/>Esmerarse en el cuido celoso de la imagen positiva de la
                        asociaci&oacute;n.</p>
                    <p>Art. 13.&nbsp;Son Derechos de los Miembros<br/>Elegir y ser elegido en los cargos de los
                        organismos de direcci&oacute;n.<br/>Intervenir con voz y voto en las asambleas.<br/>Participar
                        en las reuniones de la Directiva solamente con derecho a voz, siempre y cuando sea invitado
                        previamente.<br/>Demandar en todo o en parte el cumplimiento de los Estatutos.<br/>Disfrutar de
                        todos los beneficios proporcionados por la asociaci&oacute;n.<br/>Presentar por escrito a la
                        Directiva las sugerencias que entienda pertinentes.<br/>Obtener las informaciones que tenga a
                        bien solicitar a la junta directiva sobre cualquier asunto.<br/>Art. 14.&nbsp;Los Derechos de
                        los miembros se pierden por:&nbsp;<br/>Por fallecimiento<br/>Por no asistir a tres (3) asambleas
                        consecutivas sin causa justificada por escrito<br/>Por no pagar cinco (5) cuotas mensuales) Por
                        renuncia.<br/>Por violaci&oacute;n grave de los estatutos y reglamentos.<br/>Por cometer actos
                        re&ntilde;idos con la buena imagen de la asociaci&oacute;n.<br/>Art. 15.&nbsp;Los miembros
                        podr&aacute;n ser activos y pasivos. Ser&aacute;n miembros ACTIVOS todos aquellos que cumplan
                        con los deberes y PASIVOS los que incumplan con las obligaciones estatutarias.<br/>Estatutos -
                        Capitulo III<br/>&nbsp;<br/>CAPITULO III. ESTRUCTURA ORGANIZATIVA<br/>Art. 16.&nbsp;La
                        asociaci&oacute;n tiene el siguiente r&eacute;gimen de direcci&oacute;n:<br/>Asamblea
                        General<br/>Junta Directiva<br/>Comisiones T&eacute;cnicas<br/>N&uacute;cleos y Regionales en el
                        interior del pa&iacute;s<br/>Filiales en el exterior del pa&iacute;s.<br/>Comisi&oacute;n
                        electoral<br/>CAPITULO IV. ASAMBLEA GENERAL<br/>Art. 17.&nbsp;Est&aacute; formada por todos los
                        miembros activos y pasivos, y sus decisiones ser&aacute;n validadas por la aprobaci&oacute;n de
                        la mitad m&aacute;s uno de los presentes. Los miembros pasivos no tienen derecho a votos.<br/>Art.
                        18.&nbsp;El total de miembros a tomar en consideraci&oacute;n para fines de qu&oacute;rum
                        ser&aacute; considerado el resultante de los que sufragaron en la &uacute;ltima gesti&oacute;n
                        eleccionaria. Si en la primera convocatoria no est&aacute; presente la mayor&iacute;a simple, se
                        har&aacute; una segunda convocatoria a los 30 minutos posteriores a la hora se&ntilde;alada para
                        iniciar la asamblea y la tercera dentro de otros diez minutos.<br/>Art. 19. El qu&oacute;rum
                        v&aacute;lido para la primera convocatoria deber&aacute; ser la mitad m&aacute;s uno del total
                        de participantes en las &uacute;ltimas elecciones de la directiva. Para la segunda convocatoria
                        bastar&aacute; la presencia de un treinta (30%) por ciento y para la tercera un diez (10%).<br/>Art.
                        20.&nbsp;Son Atribuciones de la Asamblea General<br/>Nombrar la Junta Directiva a trav&eacute;s
                        de elecciones con voto secreto mediante la presentaci&oacute;n de planchas.<br/>Elegir los
                        miembros de las Comisiones T&eacute;cnicas<br/>Tratar los asuntos que no conciernen a la junta
                        directiva ni a las comisiones<br/>Designar cada dos a&ntilde;os la Comisi&oacute;n Electoral, la
                        cual estar&aacute; integrada por un Presidente, un Secretario y un Miembro. Por cada miembro de
                        dicha comisi&oacute;n se elegir&aacute; un suplente.<br/>Aprobaci&oacute;n y reforma de los
                        estatutos mediante la aprobaci&oacute;n de una mayor&iacute;a no inferior a las dos terceras
                        partes de los miembros presentes.<br/>Decidir en &uacute;ltima instancia todos los asuntos
                        concernientes a la asociaci&oacute;n.<br/>Recomendar el monto apropiado de la cuota mensual que
                        deber&aacute;n pagar los egresados para conformar el presupuesto de la asociaci&oacute;n<br/>Art.
                        20.&nbsp;Las asambleas son presididas por el Presidente de la Junta Directiva y en su ausencia
                        por quien lo represente, conforme lo establecen los estatutos.<br/>Art. 21.&nbsp;La Asamblea
                        General podr&aacute; tener un car&aacute;cter Ordinario o Extraordinario. La Asamblea General
                        Ordinaria se celebrar&aacute; cada tres meses para evaluar y/o aprobar asuntos presentados por
                        la Junta Directiva, las Comisiones, N&uacute;cleos, Regionales y las Filiales.<br/>Art. 22. La
                        asociaci&oacute;n celebrar&aacute; una Asamblea General Extraordinaria en ocasi&oacute;n de
                        cumplirse el primer a&ntilde;o de labor de cada directiva, a fin de evaluar el cumplimiento de
                        los planes de trabajo.<br/>Art. 23.&nbsp;Otra Asamblea General Ordinaria se convocar&aacute; con
                        Cuarenta y cinco (45) d&iacute;as de antelaci&oacute;n al t&eacute;rmino de la gesti&oacute;n de
                        la directiva. La fecha que se establece como t&eacute;rmino de dicha gesti&oacute;n
                        ser&aacute; el 28 de Febrero del segundo a&ntilde;o del per&iacute;odo de mandato. En esta
                        asamblea se conocer&aacute; una agenda de dos puntos: 1) Presentaci&oacute;n de las memorias
                        correspondientes; y 2) Elecci&oacute;n de la Comisi&oacute;n Electoral.<br/>Art. 24.&nbsp;Las
                        convocatorias para las asambleas (Ordinaria) deber&aacute;n hacerse con cinco (5) d&iacute;as de
                        antelaci&oacute;n a trav&eacute;s de una publicaci&oacute;n en un peri&oacute;dico de
                        circulaci&oacute;n nacional, por tel&eacute;fono y por la INTERNET y otros medios.<br/>Art.
                        25.&nbsp;La Asamblea General puede ser convocada por la junta directiva o el veinte por ciento
                        (20%) de los egresados que figuran en las votaciones de elecci&oacute;n de la directiva, para lo
                        cual deber&aacute;n entregar una solicitud por escrita debidamente firmada y dirigida al
                        Presidente de la asociaci&oacute;n.<br/>Estatutos - Capitulo V<br/>&nbsp;<br/>CAPITULO V. JUNTA
                        DIRECTIVA<br/>Art. 26. La Junta Directiva se elegir&aacute; cada dos a&ntilde;os mediante
                        elecciones generales que deber&aacute;n realizarse mediante la presentaci&oacute;n de
                        planchas.<br/>PARRAFO I:&nbsp;Las elecciones Generales deber&aacute;n efectuarse a m&aacute;s
                        tardar un mes antes de cumplirse el segundo a&ntilde;o de ejercicio de la Junta Directiva
                        vigente y que se ha establecido el d&iacute;a veintiocho (28) del mes de Febrero, fecha
                        aniversario de la incorporaci&oacute;n original de la asociaci&oacute;n.<br/>Art. 27.&nbsp;La
                        Junta Directiva estar&aacute; integrada de la siguiente manera: Presidente, Secretario de
                        Organizaci&oacute;n, Secretario de Actas y Correspondencias, Secretario de Finanzas, Secretario
                        de Prensa y Relaciones P&uacute;blicas, Secretario Asuntos Internacionales, Secretario Asuntos
                        Legales, Secretario de arte y Cultura. Y Enlace con los Consulados.<br/>Art. 28.&nbsp;La Junta
                        Directiva deber&aacute; celebrar reuniones ordinarias por lo menos dos veces al mes y
                        extraordinarias, a solicitud del presidente o cuando lo soliciten por escrito la mayor&iacute;a
                        simple de sus miembros indicando el objeto de la convocatoria.<br/>Art. 29.&nbsp;Para ser
                        miembro de la Junta Directiva se requiere lo siguiente:<br/>Tener una antig&uuml;edad
                        m&iacute;nima de dos (2) a&ntilde;os como miembro pleno de la asociaci&oacute;n.<br/>Haber
                        tenido una notable participaci&oacute;n en las actividades de la asociaci&oacute;n,
                        espec&iacute;ficamente en las asambleas ordinarias.<br/>Cumplir con funciones que les hayan sido
                        asignadas en las asambleas.<br/>Para ser candidato a los cargos de Presidente, Secretario de
                        organizaci&oacute;n y Secretario de Asuntos Internacionales el aspirante debe ser dominicano de
                        origen.<br/>Cualquier miembro de la junta directiva cesar&aacute; en sus funciones ante de
                        terminar su periodo por dimisi&oacute;n voluntaria.<br/>Art. 30.&nbsp;La Junta Directiva
                        tendr&aacute; las siguientes funciones y atribuciones:<br/>Velar por el cumplimiento estricto de
                        las decisiones que se tomen en las asambleas.<br/>Hacer valer el cumplimiento, por parte de los
                        miembros, de los presentes estatutos<br/>Promover y coordinar actividades interprofesionales de
                        los miembros.<br/>Convocar las asambleas ordinarias y extraordinarias.<br/>Rendir un informe a
                        la asamblea sobre sus reuniones ordinarias y extraordinarias.<br/>Cumplir con las tareas que les
                        sean asignadas por las asambleas.<br/>Establecer sanciones a los miembros que no asistan a tres
                        (3) asambleas consecutivas sin causa justificada. Ser&aacute;n sancionados con la
                        suspensi&oacute;n de su derecho al voto en las siguientes tres (3) asambleas.<br/>Sancionar a
                        los miembros de la directiva que sin excusa se ausenten a tres (3) reuniones consecutivas de ese
                        organismo. Ser&aacute;n sancionados con la suspensi&oacute;n de su derecho al voto en las
                        siguientes dos (2) reuniones.<br/>Promover actividades con las regionales y n&uacute;cleos del
                        interior y las filiales del exterior del pa&iacute;s.<br/>Recomendar a la Asamblea la
                        expulsi&oacute;n de alg&uacute;n miembro que haya incurrido en falta grave que afecte la imagen
                        y la integridad de la asociaci&oacute;n.<br/>Gestionar contribuciones o donativos para ser
                        utilizados en las actividades de la asociaci&oacute;n o para ayuda de los egresados necesitados)
                        Atender las solicitudes de ingreso a la asociaci&oacute;n.<br/>Representar, dirigir y
                        administrar la asociaci&oacute;n.<br/>Adoptar los acuerdos necesarios en las relaciones que se
                        mantengan con organismos oficiales y privados.<br/>Resolver cualquier caso que no se haya
                        previsto en los Estatutos.<br/>Art. 31.&nbsp;Los acuerdos de la Junta Directiva se
                        aprobar&aacute;n por mayor&iacute;a absoluta. En caso de empate de una
                        moci&oacute;n, &eacute;sta se debatir&aacute; en la siguiente reuni&oacute;n y de persistir el
                        empate, ser&aacute; el presidente quien decidir&aacute; el asunto en cuesti&oacute;n.<br/>CAPITULO
                        VI. DE LOS MIEMBROS DE LA JUNTA DIRECTIVA&nbsp;<br/>Art.32.&nbsp;SON ATRIBUCIONES DEL
                        PRESIDENTE:<br/>Ejercer la representaci&oacute;n legal de la asociaci&oacute;n y
                        desempe&ntilde;arse como vocero oficial de la entidad.<br/>Ordenar al Secretario de Actas y
                        Correspondencia convocar las reuniones de la Junta Directiva y las Asambleas.<br/>Presidir las
                        reuniones de la Junta Directiva y las asambleas.<br/>Firmar las actas y dem&aacute;s documentos
                        de la asociaci&oacute;n conjuntamente con el Secretario de Actas y Correspondencias.<br/>Clausurar
                        los debates cuando estime suficientemente discutido un tema o moci&oacute;n, tanto en las
                        reuniones de la Junta Directiva como en las asambleas.<br/>Rendir un informe de la labor de la
                        Junta Directiva en cada asamblea ordinaria, as&iacute; como del primer a&ntilde;o de ejercicio y
                        uno de rendici&oacute;n de cuentas en la asamblea que se convoque para elegir la Comisi&oacute;n
                        Electoral.<br/>Firmar junto al Secretario de Finanzas los giros bancarios de la cuenta de la
                        asociaci&oacute;n.&nbsp;<br/>Art. 33.&nbsp;SON ATRIBUCIONES DEL SECRETARIO DE ORGANIZACION<br/>Sustituir
                        al Presidente en caso de renuncia, enfermedad o fallecimiento.<br/>Darle seguimiento a las
                        decisiones de la Junta Directiva y los asuntos aprobados en las asambleas.<br/>Supervisar el
                        trabajo de las comisiones t&eacute;cnicas, las regionales y n&uacute;cleos del interior y las
                        filiales de la asociaci&oacute;n en el extranjero.<br/>Identificar los temas que se traten en la
                        opini&oacute;n p&uacute;blica nacional que puedan ser abordados por los Miembros.<br/>Representar
                        a la asociaci&oacute;n ante la C&aacute;mara de Comercio Dom&iacute;nico-Rusa.<br/>Gestionar
                        junto al Secretario de Finanzas el apoyo de empresas p&uacute;blicas y privadas que patrocinen
                        algunas actividades de la asociaci&oacute;n.<br/>Mantener actualizada la matr&iacute;cula de la
                        asociaci&oacute;n.<br/>Recibir las solicitudes de inscripci&oacute;n y tramitarlas ante la Junta
                        Directiva.<br/>Organizar conferencias, charlas y seminarios.<br/>Organizar una actividad de
                        confraternidad (cena o almuerzo) por lo menos cada tres (3) meses y en la cual se podr&iacute;an
                        realizar exposiciones sobre temas de inter&eacute;s nacional.<br/>Dirigir todos los actos
                        protocolares en las actividades de la asociaci&oacute;n.<br/>Darle seguimiento a la
                        estructuraci&oacute;n de las Comisiones t&eacute;cnicas y los n&uacute;cleos regionales.<br/>Manejar
                        iniciativas sobre servicios comunitarios y sociales que puedan prestar los egresados a los
                        sectores necesitados del pa&iacute;s.<br/>Encabezar la Comisi&oacute;n Permanente para la
                        Revalidaci&oacute;n de T&iacute;tulos) Organizar exposiciones de las publicaciones hechas por
                        los egresados.<br/>Art. 34.&nbsp;SON ATRIBUCIONES DEL SECRETARIO DE ASUNTOS INTERNACIONALES<br/>Representar
                        la asociaci&oacute;n en los eventos internacionales conjuntamente con otro miembro escogido por
                        la junta directiva.<br/>Sugerir ante la Junta Directiva las posiciones que debe asumir la
                        asociaci&oacute;n ante la C&aacute;mara de Comercio Dominico-Rusa.<br/>Enlace con los
                        representantes oficiales de Rusia, Europa del Este, Asia Central y Cuba, y de los actuales
                        pa&iacute;ses socialistas que tengan representaci&oacute;n diplom&aacute;tica en el pa&iacute;s.<br/>Realizar
                        gestiones de intercambios acad&eacute;micos, cient&iacute;ficos y comerciales con los centros de
                        ense&ntilde;anza de cualquier pa&iacute;s del mundo y en especial con los centros de
                        ense&ntilde;anza de los antiguos y actuales pa&iacute;ses socialista.<br/>Coordinar la
                        participaci&oacute;n de instituciones y empresas de los antiguos y actuales pa&iacute;ses
                        socialistas en las ferias culturales, comerciales e industriales que se celebren en el
                        pa&iacute;s.<br/>Gestionar la firma de acuerdos entre instituciones acad&eacute;micas nacionales
                        con las de los antiguos y actuales pa&iacute;ses socialistas.<br/>Diligenciar en los antiguos y
                        actuales pa&iacute;ses socialistas la obtenci&oacute;n de becas de grado y postgrado para los
                        hijos y parientes de egresados que cumplan con los requisitos exigidos.<br/>Art. 35.&nbsp;SON
                        ATRIBUCIONES DEL SECRETARIO DE FINANZAS<br/>Administrar los recursos financieros de la
                        asociaci&oacute;n conjuntamente con el Presidente.<br/>Mantener bajo su responsabilidad y
                        custodia los bienes de la asociaci&oacute;n.<br/>Cobrar las cuotas que deban pagar los miembros,
                        otorgando el correspondiente &ldquo;Recibo&rdquo; manteniendo copia como comprobante de ingreso.<br/>Presentar
                        a la Junta Directiva un &ldquo;Ejercicio Fiscal Trimestral&rdquo; donde se reflejen las entradas
                        y los gastos. Ese documento deber&aacute; firmarlo junto al Presidente.<br/>Realizar los
                        dep&oacute;sitos de los fondos ingresados a la asociaci&oacute;n en una cuenta corriente que
                        deber&aacute; tener la entidad preferiblemente en el banco estatal.<br/>Desarrollar las
                        actividades l&iacute;citas tendentes a recaudar fondos.<br/>Rechazar cualquier giro o pago no
                        ajustados a las normas de contabilidad aceptadas o que no le fuera consultado.<br/>Velar para
                        que los pagos se realicen contra presentaci&oacute;n de facturas o recibos, documentos que
                        conservar&aacute; ordenados en un archivo especial, en orden cronol&oacute;gico.<br/>Entregar
                        un &ldquo;Estado de Situaci&oacute;n&rdquo; al t&eacute;rmino de su gesti&oacute;n.<br/>Art.
                        36.&nbsp;SON ATRIBUCIONES DEL SECRETARIO DE ARTE Y CULTURA<br/>Organizar las actividades
                        culturales y art&iacute;stica de la asociaci&oacute;n<br/>Desarrollar los lazos de amistad e
                        intercambio con instituciones culturales y de las artes del pa&iacute;s y del exterior.<br/>Art.
                        37.&nbsp;SON ATRIBUCIONES DEL SECRETARIO DE RELACIONES P&Uacute;BLICAS, PRENSA Y PROPAGANDA<br/>Proyectar
                        y consolidar una buena imagen de la asociaci&oacute;n ante la opini&oacute;n p&uacute;blica
                        nacional.<br/>Organizar la propaganda de la asociaci&oacute;n.<br/>Encabezar la comisi&oacute;n
                        que manejara la p&aacute;gina Web de la asociaci&oacute;n, que estar&aacute; integrada por dos
                        (2) miembros m&aacute;s de la directiva.<br/>Divulgar las actividades de la asociaci&oacute;n en
                        los medios de comunicaci&oacute;n masiva del pa&iacute;s y a trav&eacute;s de la INTERNET.<br/>Prestar
                        asistencia a los egresados que deseen publicar alg&uacute;n trabajo de inter&eacute;s en los
                        peri&oacute;dicos nacionales o para editar alg&uacute;n libro.<br/>Publicar el peri&oacute;dico
                        de la asociaci&oacute;n en versi&oacute;n virtual e impresa.<br/>Convocar los medios de
                        comunicaci&oacute;n cuando la Junta Directiva se lo solicite.<br/>Promover actividades de
                        acercamiento entre los miembros de la prensa nacional y los de la Junta Directiva.<br/>Art.
                        38.&nbsp;SON ATRIBUCIONES DEL SECRETARIO DE ACTAS Y CORRESPONDENCIAS<br/>Redactar las actas de
                        las asambleas y reuniones de la Junta Directiva, a las cuales deber&aacute; dar lectura para su
                        aprobaci&oacute;n en la pr&oacute;xima sesi&oacute;n;<br/>Registrar en el &ldquo;Libro de
                        Actas&rdquo; todo lo que se trate en las reuniones de la Junta Directiva y en las
                        asambleas;<br/>Manejar el libro &ldquo;Control de Asistencia&rdquo; que deber&aacute;n firmar
                        los miembros que asistan a las asambleas que se celebren y en el cual los asistentes
                        deber&aacute;n actualizar sus datos;<br/>Recibir y despachar la correspondencia dejando en
                        archivo copia de los documentos enviados;<br/>Llevar un registro de cada miembro donde se
                        incluya fotocopia de su c&eacute;dula, fotocopia de su diploma y el formulario de
                        empadronamiento;<br/>Hacer las citaciones a las sesiones que disponga el Presidente;<br/>Mantener
                        a su cargo y bajo su responsabilidad el material gastable timbrado de la asociaci&oacute;n y el
                        archivo de correspondencias.<br/>Firmar junto al Presidente todos los documentos que produzcan
                        la Junta Directiva y las asambleas.<br/>Art. 39.&nbsp;SON ATRIBUCIONES DEL SECRETARIO DE ASUNTOS
                        LEGALES.<br/>Llevar los asuntos jur&iacute;dicos de la asociaci&oacute;n.<br/>Asesorar a los
                        egresados miembros de la asociaci&oacute;n que en materia de asuntos jur&iacute;dicos lo
                        soliciten a la asociaci&oacute;n.<br/>Art. 40.&nbsp;SON ATRIBUCIONES DEL SECRETARIO DE ENLACE
                        CON LOS CONSULADOS<br/>Llevar al seno de la junta directiva todo lo relacionado con los
                        consulados, especialmente con los de Rusia, Europa del Este, Asia Central y Cuba,
                        as&iacute; como con los actuales pa&iacute;ses socialistas.<br/>Presentar nuestras propuestas en
                        los consulados.<br/>Rendir informes de los asuntos de los consulados que le competan a la
                        asociaci&oacute;n.<br/>Estatutos - Caitulo VII<br/>CAPITULO VII. DE LAS COMISIONES
                        T&Eacute;CNICAS<br/>Art. 41.&nbsp;Las Comisiones T&eacute;cnicas son organismos de la estructura
                        profesional de la asociaci&oacute;n que podr&aacute;n tener capacidad de deliberar y decidir
                        dentro de las atribuciones que le confieren los presentes estatutos.<br/>Art. 42.&nbsp;Para
                        integrar las comisiones, la Junta Directiva convocar&aacute; una asamblea de car&aacute;cter
                        extraordinario en la fecha que considere apropiada, pero nunca posterior a los tres (3) meses de
                        haber asumido la direcci&oacute;n de la asociaci&oacute;n; P&Aacute;RRAFO I: En sesiones
                        anteriores a esa asamblea se podr&aacute;n elegir los coordinadores de las comisiones que se
                        tenga proyectado formalizar.<br/>Art. 43.&nbsp;Las comisiones t&eacute;cnicas estar&aacute;n
                        integradas por todos los miembros de la asociaci&oacute;n que tengan t&iacute;tulos con
                        afinidades espec&iacute;ficas.<br/>Art. 44.&nbsp;Esas comisiones deben tener un organismo
                        dirigencial denominado &ldquo;Consejo de Direcci&oacute;n&rdquo; integrado por cinco (5)
                        miembros: un Presidente, Secretario y tres (3) vocales;<br/>PARRAFO I:&nbsp;No se
                        podr&aacute; constituir m&aacute;s de una comisi&oacute;n t&eacute;cnica para aglutinar a
                        miembros con t&iacute;tulos de id&eacute;nticas afinidades.<br/>PARRAFO II:&nbsp;La
                        elecci&oacute;n de los miembros del &ldquo;Consejo de Direcci&oacute;n&rdquo; de cada
                        comisi&oacute;n t&eacute;cnica se har&aacute; en una reuni&oacute;n que a tal efecto
                        convocar&aacute; el coordinador de la misma.<br/>PARRAFO III:&nbsp;Los miembros de la Junta
                        Directiva que pertenezcan a alguna comisi&oacute;n t&eacute;cnica podr&aacute;n optar por la
                        presidencia del &ldquo;Consejo de Direcci&oacute;n&rdquo; de la misma.<br/>Art. 45.&nbsp;SON
                        ATRIBUCIONES DE LAS COMISIONES T&Eacute;CNICAS:<br/>Organizar y unir los miembros que tengan
                        afinidad en sus profesiones;<br/>Propiciar la celebraci&oacute;n de actividades
                        cient&iacute;ficas, congresos, mesa redonda, etc.<br/>Motorizar acciones para la
                        superaci&oacute;n acad&eacute;mica de los miembros y desarrollar actividades que conlleven a
                        realizar aportes a la soluci&oacute;n de los problemas que afecten al desarrollo nacional;<br/>Asumir
                        funciones de asesor&iacute;a a la Junta Directiva en la materia en que se especialice cada
                        comisi&oacute;n;<br/>Presentar informes de las labores que realicen a la Junta Directiva;<br/>El
                        Consejo de Direcci&oacute;n podr&aacute; establecer la mec&aacute;nica de trabajo de la
                        comisi&oacute;n correspondiente;<br/>Igualmente, el Consejo de Direcci&oacute;n
                        podr&aacute; expulsar de su seno a los miembros que faltaren a tres (3) sesiones consecutivas
                        sin causa justificada.<br/>CAPITULO VIII. DE LAS REGIONALES, N&Uacute;CLEOS Y FILIALES EN EL
                        EXTRANJERO.<br/>Art. 46.&nbsp;A fin de realizar una labor m&aacute;s eficiente y aglutinar la
                        mayor cantidad posible de egresados, la asociaci&oacute;n crear&aacute; las regionales y los
                        n&uacute;cleos a nivel nacional que entienda necesarios, as&iacute; como las filiales en el
                        exterior del pa&iacute;s.<br/>Art. 47.&nbsp;Tanto los n&uacute;cleos, las regionales como las
                        filiales se regir&aacute;n por los presentes estatutos y podr&aacute;n tener un reglamento
                        interno si lo consideran necesario para una jurisdicci&oacute;n espec&iacute;fica.<br/>Art.
                        48&nbsp;En los n&uacute;cleos se aglutinar&aacute;n todos los egresados que est&eacute;n
                        residiendo y trabajando en una demarcaci&oacute;n perteneciente a una regi&oacute;n determinada.<br/>Art.
                        49.&nbsp;Las filiales en el extranjero podr&aacute;n formalizarse cuando lo soliciten por lo
                        menos cinco (5) egresados que residan o trabajen en ciudades que le permitan aglutinarse sin
                        mayores dificultades.<br/>Art. 50.&nbsp;Los n&uacute;cleos, regionales y las filiales
                        tendr&aacute;n un &oacute;rgano directivo denominado &ldquo;Comisi&oacute;n Ejecutiva&rdquo;, la
                        cual estar&aacute; integrada por cinco (5) miembros: un Director, Secretario, Tesorero y dos (2)
                        vocales.<br/>Art. 51.&nbsp;Los miembros de la Comisi&oacute;n Ejecutiva de cada n&uacute;cleo o
                        filial, ser&aacute;n designados en los cargos por dos (2) a&ntilde;os por la Asamblea General de
                        dichos n&uacute;cleos.<br/>Art. 52.&nbsp;La misi&oacute;n y objetivos de los n&uacute;cleos, las
                        regionales y las filiales deber&aacute; coincidir con los de la asociaci&oacute;n.<br/>Art.
                        53.&nbsp;Son atribuciones de las Regionales, los N&uacute;cleos y de las Filiales:<br/>Realizar
                        y remitir a la Junta Directiva un censo de los egresados que residan o trabajen en la
                        jurisdicci&oacute;n de su competencia;<br/>Someter a la Junta Directiva un programa
                        m&iacute;nimo de actividades a desarrollar;<br/>Realizar sesiones ordinarias cada cuatro (4)
                        meses<br/>Remitir a la Junta Directiva un informe donde se especifique todo lo tratado en las
                        sesiones;<br/>Realizar actividades de recaudaci&oacute;n de fondos, de los cuales el 25% como
                        m&iacute;nimo deben ser depositados en la cuenta general de la asociaci&oacute;n y el restante
                        setenta y cinco 75% por ciento, sea manejado por las regionales y los n&uacute;cleos;<br/>Cualquier
                        contribuci&oacute;n o donativo que obtengan deber&aacute; ser consignado a nombre de la
                        asociaci&oacute;n, siempre y cuando no resulte de procedencia dudosa o que comprometa a la
                        misma.<br/>Los miembros de cada Comisi&oacute;n Ejecutiva de los n&uacute;cleos deber&aacute;n
                        participar en las asambleas que se celebren y los de las filiales lo har&aacute;n en
                        las &uacute;ltimas asambleas de los dos (2) a&ntilde;os correspondientes al mandato de cada
                        Junta Directiva</p>
                    <p><br/>Estatutos - Capitulo IX<br/>CAPITULO IX. R&Eacute;GIMEN ECON&Oacute;MICO<br/>Art.
                        54.&nbsp;La asociaci&oacute;n es una entidad sin fines de lucro constituida conforme a la Ley
                        122-05.<br/>Art. 55.&nbsp;El presupuesto de la asociaci&oacute;n se fundamenta en los ingresos
                        por conceptos de cuotas aportadas por los miembros, as&iacute; como contribuciones, subvenciones
                        oficiales o particulares o donativos que pueda recibir sin comprometer su independencia.<br/>Art.
                        56.&nbsp;Todos los fondos deber&aacute;n ser manejados a trav&eacute;s de la cuenta bancaria de
                        la asociaci&oacute;n, la cual es manejada por el Presidente y el Secretario de Finanzas.<br/>CAPITULO
                        X. COMISI&Oacute;N REVISORA DE CUENTAS<br/>Art. 57.&nbsp;Con la finalidad de fiscalizar el uso
                        de los recursos financieros que maneje la Junta Directiva, se proceder&aacute; a formar una
                        Comisi&oacute;n Revisora de Cuentas.<br/>Art. 58.&nbsp;La Comisi&oacute;n Revisora de Cuentas
                        estar&aacute; integrada por tres (3) miembros de la asociaci&oacute;n que ser&aacute;n
                        designados por una asamblea. Ning&uacute;n miembro de la Junta Directiva podr&aacute; formar
                        parte de esa comisi&oacute;n.<br/>Art. 59.&nbsp;Esta comisi&oacute;n durar&aacute; dos (2)
                        a&ntilde;os en sus funciones y cada a&ntilde;o deber&aacute; rendir cuenta de su cometido ante
                        la asamblea.<br/>Art. 60.&nbsp;Son atribuciones de la comisi&oacute;n comprobar que los libros
                        de ingresos y egresos y el inventario de la asociaci&oacute;n, sean llevados en orden y al
                        d&iacute;a.<br/>Art. 61.&nbsp;La comisi&oacute;n tendr&aacute; que ser asesorada por un Contador
                        P&uacute;blico Autorizado. P&Aacute;RRAFO I: La asociaci&oacute;n pagar&aacute; los honorarios
                        correspondientes al asesoramiento.<br/>CAPITULO XI. DE LAS ELECCIONES<br/>Art. 62.&nbsp;Las
                        elecciones de los directivos de la asociaci&oacute;n ser&aacute;n como lo establezca el
                        Reglamento Electoral de la entidad, el cual es aprobado por la Asamblea.<br/>Art. 63.&nbsp;Si
                        por alg&uacute;n motivo las elecciones para elegir a los miembros de la Junta Directiva no
                        pudieren realizarse en las fechas establecidas en el Reglamento Electoral, los directivos
                        continuar&aacute;n en sus cargos hasta tanto se realicen las elecciones.<br/>Art.
                        64.&nbsp;Cualquier modificaci&oacute;n o reforma que se quiera introducir al Reglamento
                        Electoral deber&aacute; ser en una asamblea extraordinaria convocada para tales
                        fines &uacute;nicamente.<br/>CAPITULO XII. DE LA MODIFICACI&Oacute;N ESTATUTARIA<br/>Art.
                        65.&nbsp;Los presentes estatutos solo pueden ser modificados en asamblea convocada en forma
                        extraordinaria espec&iacute;ficamente para tales fines.<br/>Art. 66.&nbsp;Para que sean
                        v&aacute;lidas las modificaciones ser&aacute; necesaria la aprobaci&oacute;n de las dos terceras
                        partes de los presentes.<br/>Art. 67.&nbsp;Si alguna ley aprobada por el Congreso Nacional
                        impusiera alguna modificaci&oacute;n no ser&aacute; necesario tratarla en asamblea y la Junta
                        Directiva queda facultada para realizarla.<br/>Art. 68.&nbsp;La modificaci&oacute;n puede ser
                        solicitada por la Junta Directiva o por lo menos el diez (10%) por ciento de los miembros
                        inscritos y que est&eacute;n al d&iacute;a en el cumplimiento de sus obligaciones.<br/>PARRAFO
                        I.&nbsp;Estos &uacute;ltimos deber&aacute;n dirigir una comunicaci&oacute;n por escrito a la
                        Junta Directiva, la cual tramitar&aacute; la petici&oacute;n ante la Asamblea.<br/>CAPITULO
                        XIII. DISOLUCI&Oacute;N<br/>Art. 69.&nbsp;La asociaci&oacute;n podr&aacute; ser disuelta si lo
                        acuerda una asamblea convocada con car&aacute;cter extraordinario para este fin.<br/>Art.
                        70.&nbsp;Para ello deber&aacute; registrarse una asistencia equivalente a las dos terceras
                        partes de la matr&iacute;cula activa de la asociaci&oacute;n.<br/>Art. 71.&nbsp;La
                        disoluci&oacute;n se ejecutar&aacute; si dos tercios de los presentes en la asamblea
                        as&iacute; lo aprobaran.<br/>Art. 72.&nbsp;De acordarse la disoluci&oacute;n la asamblea
                        adoptar&aacute; las medidas correspondientes respecto a los bienes de la asociaci&oacute;n,
                        as&iacute; como de la liquidaci&oacute;n de cualquier operaci&oacute;n pendiente, de acuerdo a
                        lo establecido en la Ley 122-05.<br/>PARRAFO I.&nbsp;Las funciones de liquidaci&oacute;n y
                        ejecuci&oacute;n de acuerdos ser&aacute;n competencia de una comisi&oacute;n especial nombrada
                        para tales fines por la Asamblea.</p>

                </div>

                {/*End text generated*/}

                <div className="blog-desc">
                    <p>
                        We denounce with righteous indige nation and dislike men who are so beguiled and demo realized
                        by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain
                        and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty
                        through weakness of will, which is the same as saying through shrinking from toil and pain.
                        These cases are perfectly simple and easy to distinguish. In a free hour, when our power of
                        choice is untrammelled and when nothing prevents our being able to do what we like best, every
                        pleasure is to be welcomed and every pain avoided.
                    </p>
                </div>
                <blockquote><p>Globally incubate standards compliant channels before scalable benefits. Quickly
                    disseminate superior deliverables whereas web-enabled applications.</p></blockquote>
                <div className="blog-desc mb-40">
                    <p>
                        Interactively procrastinate high-payoff content without backward-compatible data. Quickly
                        cultivate optimal processes and tactical architectures. Completely iterate covalent strategic
                        theme areas via accurate e-markets. Globally incubate standards compliant channels before
                        scalable benefits.
                    </p>
                </div>
                <h2 className="title mb-40">Economy may face double recession</h2>
                <div className="blog-desc mb-34">
                    <p>
                        Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative
                        approaches to corporate strategy foster collaborative thinking to further the overall value
                        proposition. Organically grow the holistic world view of disruptive innovation via workplace
                        diversity and empowerment. Bring to the table win-win survival strategies to ensure proactive
                        domination. At the end of the day, going forward, a new normal that has evolved from generation
                        X is on the runway heading towards a streamlined cloud solution. User generated content in
                        real-time will have multiple touchpoints for offshoring.
                    </p>
                </div>
                <div className="blog-img mb-40">
                    <img
                        src={innerImage}
                        alt=""
                    />
                </div>
                <div className="blog-desc mb-40">
                    <p>
                        Phosfluorescently engage worldwide methodologies with web-enabled technology. Interactively
                        coordinate proactive e-commerce via process-centric “outside the box” thinking. Completely
                        pursue scalable customer service through sustainable potentialities. Collaboratively
                        administrate turnkey channels whereas virtual e-tailers. Objectively seize scalable metrics
                        whereas proactive e-services. Seamlessly empower fully researched growth strategies and
                        interoperable internal or “organic” sources.
                    </p>
                </div>
                <ul className="unorder-list mb-20">
                    <li>New Construction Benefit of Service</li>
                    <li>Renovations Benefit of Service</li>
                    <li>Historic Renovations and Restorations</li>
                    <li>Additions Benefit of Service</li>
                    <li>Rebuilding from fire or water damage</li>
                </ul>
                <h2 className="title">Experts Always Ready to Maximizing Products</h2>
                <div className="blog-desc">
                    <p>
                        Proactively fabricate one-to-one materials via effective e-business. Completely synergize
                        scalable e-commerce rather than high standards in e-services. Assertively iterate resource
                        maximizing products after leading-edge intellectual capital. Capitalize on low hanging fruit to
                        identify a ballpark value added activity to beta test. Override the digital divide with
                        additional clickthroughs from DevOps. Nanotechnology immersion along the information highway
                        will close the loop on focusing solely on the bottom line.
                    </p>
                </div>
                <h4 className="sm-title">Experts Always Ready to Maximizing Products</h4>
                <div className="blog-desc">
                    <p>
                        Interactively procrastinate high-payoff content without backward-compatible data. Quickly
                        cultivate optimal processes and tactical architectures. Completely iterate covalent strategic
                        theme areas via accurate e-markets. Globally incubate standards compliant channels before
                        scalable benefits. Quickly disseminate superior deliverables whereas web-enabled applications.
                        Quickly drive clicks-and-mortar catalysts for change before vertical architectures.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default PostContent;
