import React from 'react';
import { Link } from 'react-router-dom';
const currentYear = new Date().getFullYear();

const FooterBottom = () => {
    return (
        <div className="footer-bottom">
            <div className="container">
                <div className="row y-middle">
                    <div className="col-lg-6 md-mb-10">
                        <div className="copyright md-mb-0">
                            <p>© {currentYear} Todos los derechos reservados.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end md-text-left">
                        <ul className="copy-right-menu">
                            <li><Link to="#" as="/event">Eventos</Link></li>
                            <li><Link to="#" as="/blog">Blog</Link></li>
                            <li><Link to="#" as="/contact">Contacto</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FooterBottom;
