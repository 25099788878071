/*import React, { Component } from "react";
import {  } from "react-router-dom";

class LoadTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0);
        }
    }
    render() {
        return <React.Fragment />;
    }
}

export default withRouter(LoadTop);*/


import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LoadTop = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return <React.Fragment />;
};

export default LoadTop;
