
import { Link } from 'react-router-dom';
import {useEffect, useState} from 'react';

const SinglePost = (props) => {
    const [image, setImage] = useState('');
   const { blogClass, blogImage, blogTitle, contentClass, blogDesc, blogCategory, blogAuthor, blogPublishedDate, blogButtonClass, blogButtonText } = props;

   useEffect(() => {
        if (blogImage) {
            try {
                require.resolve(`../../assets/img/posts/${blogImage}`);
                setImage(require(`../../assets/img/posts/${blogImage}`));
            } catch (e) {
                setImage(require(`../../assets/img/posts/${blogImage}`));
            }
        }
    }, [blogImage]);

   return (
        <div className={blogClass ? blogClass : 'blog-item mb-44'}>
            <div className="image-part">
                <Link to="/">
                    <img
                        src={`${image ? image : ''}`}
                        alt={blogTitle}
                    />
                </Link>
            </div>
            <div className={contentClass ? contentClass : 'blog-content'}>
                <ul className="blog-meta">
                    <li className="date"><i className="fa fa-calendar-check-o"></i> {blogPublishedDate ? blogPublishedDate : ' January 21, 2021'}</li>
                    <li className="admin"><i className="fa fa-user-o"></i> {blogAuthor ? blogAuthor : 'admin'}</li>
                </ul>
                <h3 className="title">
                    <Link to="/">
                        {blogTitle ? blogTitle : 'Open Source Job Report Show More Openings Fewer '}
                    </Link>
                </h3>
                <div className="desc">{blogDesc ? blogDesc : 'We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by the charms of pleasure of the moment, so blinded by desire, that...'}</div>
                <div className="btn-btm">
                    <div className="cat-list">
                        <ul className="post-categories">
                            <li>
                                <Link to="/blog">
                                    {blogCategory ? blogCategory : 'Application Testing'}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={blogButtonClass ? blogButtonClass : 'rs-view-btn'}>
                        <Link to="/">
                            {blogButtonText ? blogButtonText : 'Leer más'}
                        </Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SinglePost
