import React from 'react';
import { Link } from 'react-router-dom';
import FooterBottom from './FooterBottom';

import footerLogo1 from '../../../assets/img/logo/logo.png';
import posts from '../../../posts-data/posts.json'
import FooterRecentPosts from './FooterRecentPosts';

const Footer = (props) => {
    const { footerLogo, footerClass, footerTopClass } = props;
    return (
        <footer className={footerClass ? footerClass : 'rs-footer'}>
            <div className={`footer-top ${footerTopClass}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <div className="footer-logo mb-30">
                                <Link to="/" as="/">
                                    <img src={footerLogo ? footerLogo : footerLogo1} alt="Logo" />
                                </Link>
                            </div>
                            <div className="textwidget pr-60 md-pr-14">
                                <p>Asociación de Profesionales Egresados de Rusia, Europa del Este, Asia Central y Cuba</p>
                            </div>
                            <ul className="footer_social">
                                <li>
                                    <a href="#">
                                        <i className="fa fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-twitter"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="#">
                                        <i className="fa fa-instagram"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget md-mb-50">
                            <h3 className="widget-title">Dirección</h3>
                            <ul className="address-widget">
                                <li>
                                    <i className="flaticon-location"></i>
                                    <div className="desc">374 William S Canning Blvd, River MA 2721, USA</div>
                                </li>
                                <li>
                                    <i className="flaticon-call"></i>
                                    <div className="desc"><a href="tel:(+880)155-69569">(+880)155-69569</a></div>
                                </li>
                                <li>
                                    <i className="flaticon-email"></i>
                                    <div className="desc"><a href="mailto:support@rstheme.com">support@rstheme.com</a></div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 footer-widget">
                            <h3 className="widget-title">Publicaciones Recientes</h3>
                            {posts.slice(0, 2).map(post =>
                                <FooterRecentPosts
                                    blogImage={post.image}
                                    blogTitle={post.title}
                                    blogPublishedDate={post.date}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <FooterBottom />
        </footer>
    );
}

export default Footer;
