import React from 'react';
import Slider from "react-slick";
import SinglePost from './SinglePost';

// TODO: Crear backend para los posts en strapi
import posts from '../../posts-data/posts.json'

const BlogPart = () => {

    const blogSettings = {
        dots: false,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <Slider {...blogSettings}>
                {posts.map(post =>
                    <SinglePost
                        key={post.id}
                        blogClass = 'blog-item'
                        blogImage = {post.image}
                        blogCategory= {post.category}
                        blogTitle = {post.title}
                        blogDesc = {post.text}
                        blogPublishedDate = {post.date}
                        blogAuthor = {post.author}
                    />
                )}
            </Slider>
        </React.Fragment>
    );

}

export default BlogPart;
