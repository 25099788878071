import React, {useState} from 'react';
import {FormControl, InputGroup, Pagination, Table, Form} from 'react-bootstrap';
import egresadosData from '../../sample-data/egresados/egresados.json'

const EgresadosTable = () => {
  const [data, setData] = useState([...egresadosData]);

  const [filteredData, setFilteredData] = useState(data);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(100);

  const handleFilter = (e) => {
    const searchTerm = e.target.value;
    const filteredData = data.filter((item) => {
      // Split the search term into multiple words
      const searchTerms = searchTerm.split(" ");

      // If the search term is empty, don't filter
      if (searchTerm === "") {
        return true;
      }

      // If the search term has a space at the end, remove it
      if (searchTerm.endsWith(" ")) {
        searchTerms.pop();
      }

      // Check if the name contains all of the search terms
      let isMatch = true;
      for (let i = 0; i < searchTerms.length; i++) {
        const searchTermWord = searchTerms[i];

        // Check if the name contains the search term
        if (!item.Nombre.toLowerCase().includes(searchTermWord.toLowerCase())) {
          isMatch = false;
          break;
        }
      }

      return isMatch;
    });
    setFilteredData(filteredData);
  };

  const handleSort = (e) => {
    const sortField = e.target.value;
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[sortField] < b[sortField]) {
        return -1;
      }
      if (a[sortField] > b[sortField]) {
        return 1;
      }
      return 0;
    });
    setFilteredData(sortedData);
  };

  const handleRecordsPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
      <div className="pt-94 pb-70 md-pt-64 md-pb-40 gray-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <InputGroup className="mb-3 w-25">
              <InputGroup.Text>Buscar</InputGroup.Text>
              <FormControl onChange={handleFilter} />
            </InputGroup>
            <Table striped bordered hover>
              <thead>
              <tr>
                <th>Nombre</th>
                <th>Universidad</th>
                <th>CiudadPais</th>
                <th>Period</th>
                <th>Carrera</th>
                <th>Especialidad</th>
                <th>Residencia</th>
                <th>LugarTrabajo</th>
                <th>Telefono</th>
                <th>Email</th>
              </tr>
              </thead>
              <tbody>
              {filteredData
                  .slice((currentPage - 1) * recordsPerPage, currentPage * recordsPerPage)
                  .map((item, index) => (
                      <tr key={index}>
                        <td>{item.Nombre}</td>
                        <td>{item.Universidad}</td>
                        <td>{item.CiudadPais}</td>
                        <td>{item.Period}</td>
                        <td>{item.Carrera}</td>
                        <td>{item.Especialidad}</td>
                        <td>{item.Residencia}</td>
                        <td>{item.LugarTrabajo}</td>
                        <td>{item.Telefono}</td>
                        <td>{item.Email}</td>
                      </tr>
                  ))}
              </tbody>
            </Table>
            <div className="d-flex">
              <Pagination className="mr-40">
                <Pagination.First onClick={() => handlePageChange(1)} />
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />

                <Pagination.Item active>{currentPage}</Pagination.Item>
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
                <Pagination.Last onClick={() => handlePageChange(Math.ceil(filteredData.length / recordsPerPage))} />
              </Pagination>
              <InputGroup size="sm" className="w-25">
                <InputGroup.Text>Elementos por página</InputGroup.Text>
                <Form.Select as="select" onChange={handleRecordsPerPage}>
                  <option value="100">100</option>
                  <option value="75">75</option>
                  <option value="50">50</option>
                  <option value="20">20</option>
                </Form.Select>
              </InputGroup>
            </div>
          </div>
        </div>
      </div>
      </div>
  );

};

export default EgresadosTable;
