import { Link } from 'react-router-dom';

const RSMobileMenu = ({menuOpen, parentMenu, headerFullWidth }) => {

	return (
		<div className={headerFullWidth ? "container-fluid relative" : "container relative"}>
			<div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
				<div className="mobile-menu">
					<ul className="nav-menu">
						<li  className={parentMenu === 'home' ? 'current-menu-item active-menu' : ''}>
							<Link to="/">Inicio</Link>
						</li>
						<li className={parentMenu === 'estatute' ? 'current-menu-item active-menu' : ''}>
							<Link to="/estatutes">Estatutos</Link>
						</li>
						<li className={parentMenu === 'egresados' ? 'current-menu-item active-menu' : ''}>
							<Link to="/egresados">Egresados</Link>
						</li>
						<li className={parentMenu === 'egresados' ? 'current-menu-item active-menu' : ''}>
							<Link to="#">Programa de Becas</Link>
						</li>
						<li className={parentMenu === 'contact' ? 'current-menu-item active-menu' : ''}>
							<Link to="#">Contacto</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	)
}

export default RSMobileMenu;
