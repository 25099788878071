import React from 'react';
import EstatuteContent from './EstatuteContent';

const EstatuteDetails = () => {
  return (
      <>
        <EstatuteContent />
      </>
  );
};

export default EstatuteDetails;
