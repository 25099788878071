import React from 'react';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTeamThree from '../../components/Team/SingleTeamThree';
import margarita from '../../assets/img/directive/margarita.png'
import johnny from '../../assets/img/directive/johnny.png'
import toyos from '../../assets/img/directive/toyos.png'
import rigoberto from '../../assets/img/directive/rigoberto.png'
import marranzini from '../../assets/img/directive/marranzini.png'
import hector from '../../assets/img/directive/hector.png'
import enrique from '../../assets/img/directive/enrique.png'
import pedro from '../../assets/img/directive/pedro.png'
import coral from '../../assets/img/directive/coral.png'


const Directive = () => {
  return (
        <div className="rs-team style1 inner-style orange-style pt-94 pb-70 md-pt-64 md-pb-40 gray-bg">
          <div className="container">
            {/* Section Title Start */}
            <SectionTitle
                sectionClass="sec-title mb-50 md-mb-30 text-center"
                subtitleClass="sub-title orange"
                subtitle="Equipo de Trabajo"
                titleClass="title mb-0"
                title="Nuestra Directiva"
            />
            {/* Section Title End */}
            <div className="row">
              <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeamThree
                    itemClass="team-item"
                    Image={margarita}
                    Title="Ing. Margarita Franco"
                    Designation="Presidente"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeamThree
                    itemClass="team-item"
                    Image={toyos}
                    Title="Ing. Luis Toyos"
                    Designation="Secretario de Organización"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-50">
                <SingleTeamThree
                    itemClass="team-item"
                    Image={pedro}
                    Title="Ing. Pedro Valdez"
                    Designation="Secretario Científico Técnico"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <SingleTeamThree
                    teamClass="team-item"
                    Image={marranzini}
                    Title="Ing. Rafael Marranzini"
                    Designation="Secretario de Finanzas"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <SingleTeamThree
                    teamClass="team-item"
                    Image={coral}
                    Title="Lic. Coral Adames"
                    Designation="Secretaria de Prensa y Propaganda"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <SingleTeamThree
                    teamClass="team-item"
                    Image={rigoberto}
                    Title="Ing. Rigoberto Santos"
                    Designation="Secretario de Relaciones Internacionales"
                />
              </div>
              <div className="col-lg-4 col-md-6 md-mb-30">
                <SingleTeamThree
                    teamClass="team-item"
                    Image={hector}
                    Title="Ing. Hector Ortiz"
                    Designation="Secretario de Actas y Correspondencias"
                />
              </div>
              <div className="col-lg-4 col-md-6 sm-mb-30">
                <SingleTeamThree
                    teamClass="team-item"
                    Image={enrique}
                    Title="Lic. Luis Geraldo"
                    Designation="Secretario de Arte y Cultura"
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <SingleTeamThree
                    teamClass="team-item"
                    Image={johnny}
                    Title="Ing. Johnny Cedeño"
                    Designation="Secretario de Asuntos Jurídicos"
                />
              </div>
            </div>
          </div>
      </div>
  );
};

export default Directive;
