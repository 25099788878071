import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

const FooterRecentPosts = (props) => {
  const [image, setImage] = useState('');
  const {blogImage, blogTitle, blogPublishedDate} = props;

  useEffect(() => {
    if (blogImage) {
      try {
        require.resolve(`../../../assets/img/posts/${blogImage}`);
        setImage(require(`../../../assets/img/posts/${blogImage}`));
      } catch (e) {
        setImage(require(`../../../assets/img/posts/${blogImage}`));
      }
    }
  }, [blogImage]);

  return (
      <div className="recent-post mb-20">
        <div className="post-img">
          <img src={`${image ? image : ''}`} alt="blog image"/>
        </div>
        <div className="post-item">
          <div className="post-desc">
            <Link to="/">{blogTitle}</Link>
          </div>
          <span className="post-date">
            <i className="fa fa-calendar-check-o"></i>
            {blogPublishedDate}
          </span>
        </div>
      </div>
  );


};

export default FooterRecentPosts;
