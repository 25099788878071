import FeatureSingle from '../Feature/FeatureSingle';

// Feature Image
// import image2 from '../../assets/img/features/icon/2.png';
import image2 from '../../assets/img/features/icon/graduation-cap.png';
import icon4 from '../../assets/img/categories/main-home/icon/4.png';
// import image3 from '../../assets/img/features/icon/1.png';

const FeatureStyle1 = () => {
    return (
        <div className="rs-features main-home" >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-12 md-mb-30">
                        <FeatureSingle
                            itemClass="features-wrap"
                            itemImg={icon4}
                            itemTitle="Profesionales de Rusia"
                            itemDesc="En todas las ramas"
                        />
                    </div>
                    <div className="col-lg-6 col-md-12 md-mb-30">
                        <FeatureSingle
                            itemClass="features-wrap"
                            itemImg={image2}
                            itemTitle="Somos más de 5,000 egresados"
                            itemDesc="De diferentes ciudades de Rusia"
                        />
                    </div>
                   {/* <div className="col-lg-4 col-md-12">
                        <FeatureSingle
                            itemClass="features-wrap"
                            itemImg={image3}
                            itemTitle="Lifetime access"
                            itemDesc="Learn on your schedule"
                        />
                    </div>*/}
                </div>
            </div>
        </div>
    )
}

export default FeatureStyle1
