import React from 'react';
import {Helmet} from 'react-helmet';
import favIcon from '../../assets/img/favicon-a.png';
import OffWrap from '../../components/Layout/Header/OffWrap';
import Header from '../../components/Layout/Header/Header';
import Logo from '../../assets/img/logo/adopereacu-black.png';
import SiteBreadcrumb from '../../components/Common/Breadcumb';
import bannerbg from '../../assets/img/breadcrumbs/2.jpg';
import BlogMain from '../blog/BlogMain';
import Newsletter from '../../components/Common/Newsletter';
import Footer from '../../components/Layout/Footer/Footer';
import footerLogo from '../../assets/img/logo/adopereacu-white.png';
import ScrollToTop from '../../components/Common/ScrollTop';
import SearchModal from '../../components/Layout/Header/SearchModal';
import EgresadosTable from './EgresadosTable';

const Egresados = () => {
  return (
      <React.Fragment>
        <Helmet>
          <link rel="icon" href={favIcon} />
        </Helmet>
        <OffWrap />
        <Header
            parentMenu='blog'
            headerNormalLogo={Logo}
            headerStickyLogo={Logo}
            CanvasLogo={Logo}
            mobileNormalLogo={Logo}
            CanvasClass="right_menu_togle hidden-md"
            headerClass="full-width-header header-style1 home8-style4"
            TopBarClass="topbar-area home8-topbar"
            emailAddress='support@website.com'
            Location='374 William S Canning Blvd, MA 2721, USA'
        />

        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
            pageTitle="Egresados"
            pageName="Egresados"
            breadcrumbsImg={bannerbg}
        />
        {/* breadcrumb-area-End */}

        {/* Blog Main */}
        <EgresadosTable />
        {/* Blog Main End */}

        <Newsletter
            sectionClass="rs-newsletter style1 orange-style mb--90 sm-mb-0 sm-pb-80"
            titleClass="title mb-0 white-color"
        />

        <Footer
            footerClass="rs-footer home9-style main-home"
            footerLogo={footerLogo}
        />

        {/* scrolltop-start */}
        <ScrollToTop
            scrollClassName="scrollup orange-color"
        />
        {/* scrolltop-end */}

       {/* <SearchModal />*/}
      </React.Fragment>
  );
};

export default Egresados;
